import axios from "axios"
import configuration from "@/configuration"

const { apiUrl, mainApiUrl } = configuration

export const getProfile = (_id: string | null) => {
  return axios.get(`${apiUrl}/profile`, { params: { _id } })
}
export const getHistory = (_id: string) =>
  axios.get(`${apiUrl}/profile/history`, { params: { _id } })
export const upsertUser = (data: any) => axios.post(`${apiUrl}/profile`, data)
export const upsertProfileByToken = (token: any, onBehalfUserId: number) =>
  axios.post(`${apiUrl}/profile/upsert-by-token`, { token, onBehalfUserId })
export const fillDefaultFacts = (_id: any, token: string | null) =>
  axios.post(`${apiUrl}/profile/fill-default-facts`, { _id, token })
export const fillProfileFacts = (_id: any, token: string | null) =>
  axios.post(`${apiUrl}/profile/facts`, { _id, token })
export const checkPremium = (token: string | null) => axios.post(`${apiUrl}/profile/check-premium`, { token })
export const pullStravaFacts = (_id: string | null, token: string | null, userId: number) =>
  axios.post(`${apiUrl}/profile/strava`, {
    _id,
    token,
    userId
  })
export const restoreQuestionsAndAnswers = (_id: any, skipStrava: number) =>
  axios.get(`${apiUrl}/assessment/restore-questions-and-answers`, { params: { _id, skipStrava } })
export const getNextQuestion = (data: any) => axios.post(`${apiUrl}/assessment/next-question`, data)
export const pushPlan = (data: any, timeout: number) => axios.post(`${apiUrl}/plan/send`, data, { timeout })
export const getValues = () => axios.get(`${apiUrl}/values`)
export const loadFromHistory = (_id: string) => axios.post(`${apiUrl}/plan/${_id}`)
export const deleteHistory = (historyId: string, deleteFromTD: boolean, token: any) =>
  axios.delete(`${apiUrl}/history`, { data: { historyId, deleteFromTD, token } })
export const changeAndGetPlan = (params: any) => axios.post(`${apiUrl}/plan`, { ...params })
export const calcWorkoutStats = (workoutsByDays: any) =>
  axios.post(`${apiUrl}/plan/calc-workout-stats`, { workoutsByDays })
export const clonePlan = (_id: any, historyId: any, startDate?: string) =>
  axios.post(`${apiUrl}/history/clone`, { _id, historyId, startDate })
export const getRoutes = (params: any) => axios.get(`${apiUrl}/routes`, { params })

export const getRouteGPX = (id: any) =>
  axios.get(`https://maptoolkit.net/export/outdoorish_bikemap_routes/${id}.gpx?cache_buster=93379716`)
export const getRouteDetails = (id: number, type: string) =>
  axios.get(`${apiUrl}/routes/details`, { params: { id, type } })
export const getTranslation = (locale: string, text: string, model: string, token: any) =>
  axios.post(
    `${mainApiUrl}/api/translations`,
    { locale, text, model },
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  )
export const fetchTDUser = (token: string) => {
  return axios.get(`${mainApiUrl}/api/users/get_me`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
