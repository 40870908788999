import axios from "axios"
import * as Sentry from "@sentry/vue"
import { sentryUser } from "@/store"
const responseInterceptor: any = () =>
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function(error) {
      if (process.env.APP_ENV === "production") {
        if (error && error.response?.status > 400) {
          const status = error.response?.status
          const url = error.config?.url
          const method = error.config?.method?.toUpperCase()
          const responsePayload = error.response?.data
          const requestBody = error.config?.data
          Sentry.captureException(error, {
            tags: {
              url: url || "unknown",
              method: method || "unknown",
              status: status || "no_status"
            },
            extra: {
              user: sentryUser || "noUser",
              responsePayload: responsePayload || "no_response_payload",
              requestBody: requestBody || "no_request_body"
            }
          })
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )
export default responseInterceptor
